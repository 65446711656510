// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js": () => import("./../../../node_modules/@rocketseat/gatsby-theme-docs-core/src/templates/docs-query.js" /* webpackChunkName: "component---node-modules-rocketseat-gatsby-theme-docs-core-src-templates-docs-query-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cli-index-tsx": () => import("./../../../src/pages/cli/index.tsx" /* webpackChunkName: "component---src-pages-cli-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-opensource-index-js": () => import("./../../../src/templates/opensource/index.js" /* webpackChunkName: "component---src-templates-opensource-index-js" */)
}


export default {
  colors: {
    primary: "#008ECF",
    background: "#FFFFFF",
    shape: `#e3f1f8`,
    title: `#020B13`,
    text: `#41484E`,
    components: {
      blockquote: {
        background: `#feebc8`,
        text: `#2d3748`,
      },
    },
  },
};
